
import { defineComponent, inject } from "vue";
import OnboardingSteps from "@components/onboarding/OnboardingSteps.vue";
import OnboardingInteractor from "@interactors/onboarding/Onboarding.interactor";
import OnboardingStepsSkeleton from "@components/skeleton/OnboardingStepsSkeleton.vue";
import OnboardingResourceGuide from "@components/onboarding/OnboardingResourceGuide.vue";

export default defineComponent({
    name: "FirstTimeSetup",
    components: {
        OnboardingSteps,
        OnboardingStepsSkeleton,
        OnboardingResourceGuide,
    },
    setup() {
        const originUrl = window.location.origin;

        return {
            originUrl,
            progressLoaded: false,
        };
    },
    data() {
        const allSteps = OnboardingInteractor.getAllSteps();
        const mapping = OnboardingInteractor.getUserFirstTimeSetupMapping();
        let stepsProgress: any;
        let $mitt: any;

        return {
            $mitt,
            allSteps,
            stepsProgress,
            mapping,
        };
    },
    async mounted() {
        this.$mitt = inject("$mitt");
        this.stepsProgress = await OnboardingInteractor.getFirstTimeSetupProgress();
        this.progressLoaded = true;
        this.$forceUpdate();
    },
    methods: {
        async stateUpdate(step): Promise<void> {
            await OnboardingInteractor.updateFirstTimeSetupProgress(step);
            return new Promise((resolve) => {
                resolve();
            });
        },
    },
});
